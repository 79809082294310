import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші послуги | Відкрийте для себе наші послуги на Teplop
			</title>
			<meta name={"description"} content={"Де експертиза зустрічається з емпатією"} />
			<meta property={"og:title"} content={"Наші послуги | Відкрийте для себе наші послуги на Teplop"} />
			<meta property={"og:description"} content={"Де експертиза зустрічається з емпатією"} />
			<meta property={"og:image"} content={"https://ua.teplop.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.teplop.com/img/436357254.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.teplop.com/img/436357254.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.teplop.com/img/436357254.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.teplop.com/img/436357254.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.teplop.com/img/436357254.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.teplop.com/img/436357254.png"} />
		</Helmet>
		<Components.Heaeder />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші послуги
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Ласкаво просимо на сторінку "Послуги" на Teplop, де ми описуємо широту та глибину нашої юридичної підтримки, яка допоможе вам впевнено та чітко орієнтуватися у вашому юридичному шляху. Наші пропозиції побудовані на основі індивідуального підходу, інновацій та розширення можливостей, гарантуючи, що кожен клієнт отримає рекомендації, необхідні для подолання своїх юридичних проблем.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Звʼязатися з нами
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://ua.teplop.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Огляд наших юридичних послуг
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Розширення ваших можливостей за допомогою комплексних юридичних рішень.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Сімейне право
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Супровід на складних життєвих етапах: Від шлюборозлучного процесу до угод про опікунство - наш чуйний підхід гарантує вам і вашим близьким підтримку на кожному кроці.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Господарське право
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Фундаментальна підтримка вашого підприємства: Незалежно від того, чи ви починаєте свою діяльність, чи розширюєте її, ми надаємо основні юридичні послуги, включаючи реєстрацію, контракти та консультування з питань дотримання вимог законодавства, щоб захистити майбутнє вашого бізнесу.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Інтелектуальна власність
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Захист ваших інновацій: Орієнтуйтеся в складних питаннях інтелектуальної власності з нашими експертами, які захистять ваші творіння та гарантують, що ваші ідеї залишаться вашими.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Трудове право
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Створюємо справедливі робочі місця: Від розробки посібників для працівників до консультування з питань трудових спорів, ми прагнемо сприяти створенню здорового, відповідного законодавству робочого середовища як для роботодавців, так і для працівників.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Право нерухомості
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Спрощення операцій з нерухомістю: Наша команда пропонує чіткі та лаконічні рекомендації у лабіринтах права нерухомості, незалежно від того, чи купуєте ви нерухомість, чи продаєте її, чи керуєте нею.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Заповіти, трасти та нерухомість
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Планування майбутнього: Забезпечте душевний спокій для себе та своєї сім'ї за допомогою наших заповітів, трастів та послуг з планування нерухомості, розроблених для захисту вашої спадщини та побажань.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://ua.teplop.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Побудуйте свій юридичний шлях разом з нами
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ми запрошуємо вас зв'язатися з нами, щоб дізнатися, як наші послуги можуть бути адаптовані до вашої унікальної ситуації. Будь то консультація в нашому офісі або детальне обговорення по телефону чи електронній пошті, ми тут для того, щоб покращити ваш юридичний досвід та направити вас до успішного вирішення проблеми.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});